import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  PlanBnr,
  Access,
  ImgList,
  InfoTable,
  FacilityPostBtns,
  FacilityDNav,
  KitchenList,
  AmenitieList,
  FaqList,
  wovnCheck,
} from '../../../components/_index';


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const [floorPdf, setFloorPdf] = useState('floorPlan_kamanza-nijo_ja.pdf')
  wovnCheck(function (wovnCode: string) {
    const pdffile = wovnCode !== 'ja' ? 'floorPlan_kamanza-nijo_en.pdf' : 'floorPlan_kamanza-nijo_ja.pdf';
    setFloorPdf(pdffile)
  });
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility_d" className="nishijin-villa under">
        <div id="facility_dMv">
          <div className="mvCont">
            <p className="ico">#2</p>
            <h2 className="facility_dMvTit">京の温所 釜座二条<span>KAMANZA-NIJO</span></h2>
            <div className="mvImg" style={{ backgroundImage: 'url(/images/facility/Kamanza_22.jpg)' }}></div>
          </div>
          <FacilityDNav data={[
            {
              anchor: "#facility_dInfo",
              label: <>施設情報</>,
            },
            {
              anchor: "#facility_dAmenitie",
              label: <>アメニティ</>,
            },
            {
              anchor: "#facility_dAccess",
              label: <>アクセス</>,
            },
            {
              anchor: "#facility_dPlan",
              label: <>プラン一覧</>,
            },
            {
              anchor: "#facility_dFaq",
              label: <>よくあるご質問</>,
            },
          ]} />
        </div>
        <div id="facility_dWrap" className="wd">
          <div id="facility_dInfoWrap">
            <section id="facility_dInfoAbout">
              <h2 className="tit">建築家 中村好文と<br className="sp" />「ミナ ペルホネン」皆川明が手がける<br />現代生活に寄り添う<br className="sp" />「住まい」としての京町家</h2>
              <p>二条城にほど近く、アクセスが良い街中でありながら、静かに過ごせる釜座二条。<br />築150年（推定）のちいさな京町家が、現代生活に寄り添った、光あふれる「住まい」として生まれ変わりました。<br />住宅設計の経験が豊富な中村好文ならではのらせん階段や、使い勝手のよいキッチン、樹齢100年の古木をのぞむ庭のライブラリー。また宿内のあちこちには「ミナ ペルホネン」のテキスタイルがあしらわれ、皆川明によるセレクトのアートピースが空間を彩ります。<br />ちいさな京町家が重ねてきた歴史を感じながら、自宅で過ごしているようなくつろぎの時間をお過ごしください。</p>
              <FacilityPostBtns data={[
                {
                  href: `/assets/files/facility/${floorPdf}`,
                  blank: true,
                  label: <>間取り図<small>（PDF）</small></>,
                },
                {
                  href: "https://archihatch.com/archicle/2113/",
                  blank: true,
                  label: <>3D 間取図（ARCHI HATCH）</>,
                },
                {
                  href: "/news/recommend/#kamanza-nijo",
                  blank: false,
                  label: <>周辺のおすすめ</>,
                },
              ]} />
            </section>
            <section id="facility_dInfo">
              <h2 className="tit">INFORMATION</h2>
              <p className="post">京の温所 釜座二条 #2<br />
                <a href="https://goo.gl/maps/eTCVHi3SguA2" target="_blank">京都市中京区釜座通二条下る上松屋町690-2</a>
              </p>
              <InfoTable data={[
                {
                  label: <>広さ</>,
                  value: <>2階建て82平米（1棟貸切）</>,
                },
                {
                  label: <>定員</>,
                  value: <>1～4名</>,
                },
                {
                  label: <>部屋</>,
                  value: <>&lt;1階&gt; キッチン＆ダイニング　庭のライブラリー<br />風呂（高野槇）　洗面　トイレ　トランクルーム<br />&lt;2階&gt; ベッドルーム　和室</>,
                },
                {
                  label: <>寝具</>,
                  value: <>シングルベッド2台　ふとん2組（3名よりご用意）</>,
                },
                {
                  label: <>設備</>,
                  value: <>エアコン、空気清浄機、洗濯機、床暖房、浄水器（クリンスイ）</>,
                },
                {
                  label: <>テレビ</>,
                  value: <>なし</>,
                },
                {
                  label: <>スピーカー</>,
                  value: <>Bluetoothスピーカー</>,
                },
                {
                  label: <>Wi-Fi</>,
                  value: <>無料Wi-Fi（無線LAN）</>,
                },
                {
                  label: <>禁煙</>,
                  value: <>建物・敷地内　全面禁煙</>,
                },
              ]} />
            </section>
          </div>
          <div id="facility_dGallery" className="facility_dSection">
            <ImgList data={[
              '/images/facility/Kamanza_04.jpg',
              '/images/facility/Kamanza_07.jpg',
              '/images/facility/Kamanza_26.jpg',
              '/images/facility/Kamanza_29.jpg',
              '/images/facility/20220611kamamina.jpg',
              '/images/facility/20220611kamamina3.jpg',
              '/images/facility/Kamanza_14.jpg',
              '/images/facility/Kamanza_15.jpg',
              '/images/facility/Kamanza_28.jpg',
            ]} />
          </div>
          <section id="facility_dIntro" className="facility_d facility_dSection">
            <h2 className="facility_dTit">樹齢100年の古木をのぞむ<br className="sp" />庭のライブラリー</h2>
            <p>おうちの歴史をずっと見てきた樹齢100年のイヌマキの古木。それをのぞむようにあるのが手がけた2人もお気に入りの庭のライブラリー。<br />
              「そのほうがドラマティックだから」とお客様ご自身で鍵を開けていただく仕組みです。約200冊ある書籍は「BACH」代表 ブックディレクターの幅 允孝が選書しました。京都の旅をより印象深いものにしてくれる本に出合える場所です。
            </p>
            <ImgList data={[
              '/images/facility/Kamanza_21.jpg',
              '/images/facility/Kamanza_22.jpg',
              '/images/facility/Kamanza_25.jpg',
            ]} />
          </section>
          <section id="facility_dKitchen" className="facility_d facility_dSection">
            <div>
              <h2 className="facility_dTit">庭を眺めながら至福のときを過ごすキッチン＆ダイニング</h2>
              <p>京都ならではの食材で、温かい食事をつくる楽しみを「京都で食べる」記憶にしていただけるキッチン＆ダイニングです。<br />コンパクトなのに動きやすく、使い勝手のよいキッチン。そして眺めがよく、居心地のいいダイニング。あたたかく包み込まれるような空間です。<br />料理が楽しくなる、用と美を兼ね備えた調理器具や、食器、カトラリーもご用意しています。
              </p>
            </div>
            <ImgList data={[
              '/images/facility/Kamanza_17.jpg',
              '/images/facility/Kamanza_18.jpg',
              '/images/facility/Kamanza_20.jpg',
            ]} />
            <KitchenList />
          </section>
          <AmenitieList data={[
            '/images/facility/Kamanza_16.jpg',
            '/images/facility/amenities_bath.jpg',
            '/images/facility/IMG_2577.jpg',
          ]} text={<>中村好文デザインの卵型をした高野槇風呂。建具にはヒノキを使用しています。<br /></>} />

          <section id="facility_dAccess">
            <Access data={{
              train: <>地下鉄烏丸線「烏丸御池」駅 2番出口　徒歩約7分</>,
              bus: <>「二条城前」下車　徒歩約6分<br />「新町御池」下車　徒歩約4分<br />「烏丸御池」下車　徒歩約7分</>,
              taxi: "",
              parking: <>駐車場はございません。（近隣パーキング：徒歩5分圏内数か所あり）</>,
              img: "",
              maplink: "https://goo.gl/maps/eTCVHi3SguA2",
              map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3267.748129355585!2d135.7536226!3d35.0130096!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600109f954d8e697%3A0x6ecdaa54443bbd21!2z5Lqs44Gu5rip5omAIOmHnOW6p-S6jOadoQ!5e0!3m2!1sja!2sjp!4v1533088695964",
            }} />
          </section>
          <section id="facility_dPlan">
            <PlanBnr roomcd="0000001666" />
          </section>
          <FaqList />
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
